import "./swap.css";
import {
  Unstable_Popup as BasePopup,
  PopupContext,
} from "@mui/base/Unstable_Popup";
import React, { useEffect, useState } from "react";
import PopupBody from "./PopupBody";
import usdtLogo from "../../images/tokens/usdt.png";
import wethLogo from "../../images/tokens/weth.png";
import btcbLogo from "../../images/tokens/btcb.png";
import ethLogo from "../../images/tokens/eth.png";
import wbnbLogo from "../../images/tokens/wbnb.png";
import wmatic from "../../images/tokens/wmatic.png";
import usdcLogo from "../../images/tokens/usdc.png";
import wbtcLogo from "../../images/tokens/wbtc.png";
import uniswapLogo from "../../images/platforms/uniswap.png";
import pancakeswapLogo from "../../images/platforms/pancakeswap.png";
import inverseImage from "../../images/inverseLogo.png";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import {
  approve,
  getAmountOut,
  getApprove,
  getBalance,
  getBestOut,
  getDecimals,
  swapV3,
} from "./functions.js";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Box, Modal } from "@mui/material";
import { getPointsAirdrop } from "../header/functions.js";

const SwapPart = () => {
  const [anchor, setAnchor] = useState(null);
  const [anchor2, setAnchor2] = useState(null);
  const chain = useSelector((state) => state.User.chain);
  const isLogged = useSelector((state) => state.User.isLogged);
  const address = useSelector((state) => state.User.wallet);
  const airdropRedux = useSelector((state) => state.User.airdrop);
  const [bestPath, setBestPath] = useState(["2"]);
  const [rotate, setRotate] = useState(false);
  const [amountIn, setAmountIn] = useState(0);
  const [amountOut, setAmountOut] = useState(0);
  const [middleAmount, setMiddleAmount] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [partOne, setPartOne] = useState(true);
  const [final, setFinal] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const whitelistedChains = ["0x89", "0x38"];

  //SWAP VARIABLES
  const [approvedFirst, setApprovedFirst] = useState(false);
  const [approvedSecond, setApprovedSecond] = useState(false);
  const dispatch = useDispatch();
  const changeSwap = React.useCallback(async () => {
    dispatch({
      type: "changeWallet",
      CurrentWallet: address,
      isLogged: true,
      chain: chain,
      airdrop: airdropRedux + 1,
    });
  });

  const handleOpen = async () => {
    if (amountIn != 0) {
      setOpen(true);
      await handleNewFirstApproved();
    } else
      toast.error("change amounts!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const changeAmountIn = (event) => {
    let initialNumber = event.target.value;
    let cleanNumber = initialNumber.replace(/^0+(?=\d)/, "");
    let amount = cleanNumber;
    if (amount > balanceIn) {
      setAmountIn(balanceIn);
    } else setAmountIn(amount);
    setAnchor(null);
  };

  useEffect(() => {
    if (seconds >= 1 && !fetching) {
      if (amountIn != 0) {
        setFetching(true);
        const id = toast.loading("fetching best swap...", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        const bestOutAmount = getBestOut(tokenIn, tokenOut, amountIn, chain);
        bestOutAmount
          .then((response) => {
            let amountOut = response["shortOutputMax"];
            setBestPath(response);
            setAmountOut(amountOut.toFixed(12));
            try {
              if (typeof response["pathInfo"]["path"][0] == "object") {
                let tokenInAdd = response["pathInfo"]["path"][0][0];
                let tokenOutAdd = response["pathInfo"]["path"][0][1];
                let fees = response["pathInfo"]["fees"][0];
                const middleAmountOut = getAmountOut(
                  tokenInAdd,
                  tokenOutAdd,
                  fees,
                  amountIn,
                  chain
                );

                middleAmountOut.then((result) => {
                  setMiddleAmount(result);
                });
              }
            } catch (e) {
              console.log(e);
            }
            toast.update(id, {
              render: "Got it!",
              type: "success",
              isLoading: false,
              autoClose: 1300,
            });
          })
          .catch((error) => {
            toast.update(id, {
              render: "Error,retry!",
              type: "error",
              isLoading: false,
              autoClose: 1300,
            });
          });
      } else setAmountOut(0);
    }
  }, [seconds]);

  useEffect(() => {
    clearInterval();
    let interval = null;
    setFetching(false);
    setSeconds(0);
    if (isActive) {
      setIsActive(false);
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
    }
  }, [amountIn]);

  const mapNameToInfo = {
    USDT: { logo: usdtLogo, chains: ["0x89", "0x38"] },
    WETH: { logo: wethLogo, chains: ["0x89"] },
    BTCB: { logo: btcbLogo, chains: ["0x38"] },
    ETH: { logo: ethLogo, chains: ["0x38"] },
    WBNB: { logo: wbnbLogo, chains: ["0x38"] },
    WMATIC: { logo: wmatic, chains: ["0x89"] },
    USDC: { logo: usdcLogo, chains: ["0x89"] },
    USDCe: { logo: usdcLogo, chains: ["0x89"] },
    WBTC: { logo: wbtcLogo, chains: ["0x38", "0x89"] },
  };

  const startingTokens = [
    "USDT",
    "WETH",
    "BTCB",
    "ETH",
    "WBNB",
    "WMATIC",
    "USDCe",
    "USDC",
    "WBTC",
  ];

  const [listTokensIn, setTokensIn] = React.useState(startingTokens);
  const [listTokensOut, setTokensOut] = React.useState(startingTokens);

  const [balanceIn, setBalanceIn] = React.useState(0);

  useEffect(() => {
    balance();
  }, [address]);

  const getTokenIn = () => {
    return "USDT";
  };

  const getTokenOut = () => {
    return "WBTC";
  };

  const [tokenIn, setTokenIn] = React.useState(getTokenIn());
  const [tokenOut, setTokenOut] = React.useState(getTokenOut());
  const handleClick = (event) => {
    setAnchor(anchor ? null : event.currentTarget);
    setAnchor2(null);
  };

  const handle2Click = (event) => {
    setAnchor2(anchor2 ? null : event.currentTarget);
    setAnchor(null);
  };

  const addressToName = {
    "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174": "USDCe",
    "0x55d398326f99059fF775485246999027B3197955": "USDT",
  };

  const handleTokenClickIn = async (element, close) => {
    setTokenIn(element);
    setPartOne(true);
    setFinal(false);
    if (close) handleClick();
    const balance = await getBalance(element, chain, address);
    setBalanceIn(truncate(balance, 7));
    if (amountIn != 0) {
      const bestOutAmount = getBestOut(element, tokenOut, amountIn, chain);
      bestOutAmount
        .then((response) => {
          let amountOut = response["shortOutputMax"];
          setBestPath(response);
          setAmountOut(amountOut.toFixed(12));
          try {
            if (typeof response["pathInfo"]["path"][0] == "object") {
              let tokenInAdd = response["pathInfo"]["path"][0][0];
              let tokenOutAdd = response["pathInfo"]["path"][0][1];
              let fees = response["pathInfo"]["fees"][0];
              const middleAmountOut = getAmountOut(
                tokenInAdd,
                tokenOutAdd,
                fees,
                amountIn,
                chain
              );
              middleAmountOut.then((result) => {
                setMiddleAmount(result.toFixed(12));
              });
            }
          } catch {}
        })
        .catch((error) => {
          toast.error(id, {
            render: "Error,change amount!",
            type: "error",
            isLoading: false,
            autoClose: 1300,
          });
        });
    } else setAmountOut(0);
  };

  const handleNewFirstApproved = async () => {
    let amountApproved = await getApprove(address, tokenIn, chain);
    let isApproved = amountApproved > amountIn;
    setApprovedFirst(isApproved);
  };

  async function approveToken(token) {
    const id = toast.loading("approving...", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    let result = await approve(address, token, chain);
    if (result == 0) {
      let resultString = token + " approved!";
      toast.update(id, {
        render: resultString,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      if (token == tokenIn) setApprovedFirst(true);
      else setApprovedSecond(true);
    } else {
      if (token == tokenIn) setApprovedFirst(false);
      else setApprovedSecond(true);
      toast.update(id, {
        render: "Error during approve!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  }

  const handleTokenClickOut = (element, close) => {
    setTokenOut(element);
    setPartOne(true);
    setFinal(false);
    if (close) handle2Click();
    if (amountIn != 0) {
      const id = toast.loading("fetching best swap...", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      const bestOutAmount = getBestOut(tokenIn, element, amountIn, chain);
      bestOutAmount
        .then((response) => {
          let amountOut = response["shortOutputMax"];
          setBestPath(response);
          setAmountOut(amountOut.toFixed(12));
          try {
            if (typeof response["pathInfo"]["path"][0] == "object") {
              let tokenInAdd = response["pathInfo"]["path"][0][0];
              let tokenOutAdd = response["pathInfo"]["path"][0][1];
              let fees = response["pathInfo"]["fees"][0];
              const middleAmountOut = getAmountOut(
                tokenInAdd,
                tokenOutAdd,
                fees,
                amountIn,
                chain
              );
              middleAmountOut.then((result) => {
                setMiddleAmount(result.toFixed(12));
              });
            }
          } catch {}
          toast.update(id, {
            render: "Got it!",
            type: "success",
            isLoading: false,
            autoClose: 1000,
          });
        })
        .catch((reason) => {
          toast.update(id, {
            render: "Error,change amount!",
            type: "error",
            isLoading: false,
            autoClose: 1300,
          });
        });
    } else setAmountOut(0);
  };

  async function swap(tokenIn, tokenOut, fee) {
    if (amountIn == 0) {
      toast.error("change amounts!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      const id = toast.loading("swapping...", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      let isDoublePath = typeof bestPath["pathInfo"]["path"][0] == "object";
      //SWAP PART
      let res = await swapV3(tokenIn, tokenOut, fee, amountIn, chain);

      if (res == 0) {
        if (isDoublePath) {
          if (partOne) setPartOne(false);
          else setFinal(true);
        } else {
          setFinal(true);
        }
        changeSwap();
        await balance();
        toast.update(id, {
          render: "Swap Successful!",
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
      } else {
        toast.update(id, {
          render: "Swap Failed!",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      }
    }
  }

  const handleFinalClose = () => {
    setOpen(false);
    setAmountIn(0);
    setPartOne(true);
    setFinal(false);
  };

  useEffect(() => {
    if (amountIn != 0) {
      updatePrice();
    }
    balance();
  }, [tokenIn]);

  const handleChangeAmount = () => {
    setAmountIn(0);
  };

  useEffect(() => {
    setAmountIn(0);
    setPartOne(true);
    setFinal(false);
    setTokenOut(getTokenOut());
    setTokenIn(getTokenIn());
  }, [chain]);

  const listItemsIn = listTokensIn.map((element, index) => {
    let currentChain = "0x89";
    if (
      window.ethereum &&
      whitelistedChains.includes(window.ethereum.chainId)
    ) {
      currentChain = window.ethereum.chainId;
    }
    if (
      mapNameToInfo[element]["chains"].includes(currentChain) &&
      element != tokenIn &&
      element != tokenOut
    ) {
      return (
        <td
          key={index}
          className="listChains"
          onClick={() => handleTokenClickIn(element, true)}
        >
          <img src={mapNameToInfo[element]["logo"]} className="chain" />
        </td>
      );
    }
  });

  async function updatePrice() {
    const id = toast.loading("fetching best swap...", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    const bestOutAmount = getBestOut(tokenIn, tokenOut, amountIn, chain);
    bestOutAmount
      .then((response) => {
        let amountOut = response["shortOutputMax"];
        setAmountOut(amountOut.toFixed(12));
        setBestPath(response);
        try {
          if (typeof response["pathInfo"]["path"][0] == "object") {
            let tokenInAdd = response["pathInfo"]["path"][0][0];
            let tokenOutAdd = response["pathInfo"]["path"][0][1];
            let fees = response["pathInfo"]["fees"][0];
            const middleAmountOut = getAmountOut(
              tokenInAdd,
              tokenOutAdd,
              fees,
              amountIn,
              chain
            );
            middleAmountOut.then((result) => {
              setMiddleAmount(result);
            });
          }
        } catch (e) {
          console.log(e);
        }

        toast.update(id, {
          render: "Got it!",
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
      })
      .catch((reason) => {
        toast.update(id, {
          render: "Error,retry!",
          type: "error",
          isLoading: false,
          autoClose: 1300,
        });
      });
  }

  const tokenLogo = (token) => {
    const abiTokens = require("./data/tokenData.json");
    let name = abiTokens[token];
    return mapNameToInfo[name]["logo"];
  };

  const listItemsOut = listTokensOut.map((element, index) => {
    let currentChain = "0x89";
    if (
      window.ethereum &&
      whitelistedChains.includes(window.ethereum.chainId)
    ) {
      currentChain = window.ethereum.chainId;
    }
    if (
      mapNameToInfo[element]["chains"].includes(currentChain) &&
      element != tokenIn &&
      element != tokenOut
    ) {
      return (
        <td
          key={index}
          className="listChains"
          onClick={() => handleTokenClickOut(element, true)}
        >
          <img src={mapNameToInfo[element]["logo"]} className="chain" />
        </td>
      );
    }
  });

  function truncate(num, places) {
    return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
  }

  async function balance() {
    const balance = await getBalance(tokenIn, chain, address);
    setBalanceIn(truncate(balance, 7));
  }
  const handleMaxIn = () => {
    setAmountIn(balanceIn);
  };

  const handleInvert = () => {
    let temp = tokenIn;
    setTokenIn(tokenOut);
    setTokenOut(temp);

    balance();
    setRotate(!rotate);
  };

  const styleInverse = {
    transform: rotate ? "scaleX(-1)" : "",
    transition: "transform 150ms ease",
  };

  const open = Boolean(anchor);
  const open2 = Boolean(anchor2);
  const id = open ? "simple-popper" : undefined;

  return (
    <div className="UnderSwapPart">
      <div className="swapPart">
        <div className="uppdarZoneSwap">
          <span className="spendText">You spend</span>
          <div className="countdownPart">
            <CountdownCircleTimer
              isPlaying={amountOut != 0 ? true : false}
              duration={30}
              colors="#38378b"
              size={20}
              strokeWidth={1}
              onComplete={() => {
                updatePrice();
                return { shouldRepeat: true };
              }}
            />
          </div>
        </div>
        <div className="tokensInputZone">
          <div className="amountInDiv">
            <input
              className="inputAmountToSwap"
              inputMode="decimals"
              value={amountIn}
              type="number"
              onChange={changeAmountIn}
              placeholder="0"
            ></input>
            <div className="balanceIn">
              <p className="textBalanceIn">
                Balance: {balanceIn} {tokenIn}
              </p>
              <p className="maxTextBalance" onClick={handleMaxIn}>
                max
              </p>
            </div>
          </div>
          <div className="buttonPart">
            <button
              onClick={handleClick}
              aria-describedby={id}
              className="tokenSelectedButton"
            >
              <div className="selectCoinDiv">
                <div className="tokenSelectedDiv">
                  <img
                    src={mapNameToInfo[tokenIn]["logo"]}
                    className="logoToken"
                  />
                  <p className="textTokenIn">{tokenIn}</p>
                </div>
              </div>
            </button>
            <BasePopup id={id} open={open} anchor={anchor}>
              <PopupBody>{listItemsIn}</PopupBody>
            </BasePopup>
          </div>
        </div>

        <div style={styleInverse}>
          <img
            src={inverseImage}
            className="inverseImage"
            onClick={handleInvert}
          />
        </div>

        <div className="tokensInputZone">
          <div className="amountInDiv">
            <input
              className="inputAmountToSwap"
              inputMode="decimals"
              type="number"
              value={amountOut}
              disabled
              placeholder="0"
            ></input>
          </div>
          <div className="buttonPart">
            <button
              onClick={handle2Click}
              aria-describedby={id}
              className="tokenSelectedButton"
            >
              <div className="selectCoinDiv">
                <div className="tokenSelectedDiv">
                  <img
                    src={mapNameToInfo[tokenOut]["logo"]}
                    className="logoToken"
                  />
                  <p className="textTokenIn">{tokenOut}</p>
                </div>
              </div>
            </button>
            <BasePopup id={id} open={open2} anchor={anchor2}>
              <PopupBody>{listItemsOut}</PopupBody>
            </BasePopup>
          </div>
        </div>
        <div className="buttonSwapDiv">
          {isLogged && chain != "0x69" ? (
            <div>
              <button className="swapButton" onClick={handleOpen}>
                Review Swap
              </button>
              <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box>
                  <div>
                    <div>
                      {amountOut != 0 ? (
                        <div
                          className={
                            typeof bestPath["pathInfo"]["path"][0] ==
                              "object" && amountOut != 0
                              ? "boxTwoSwap"
                              : "boxSwap"
                          }
                        >
                          {!final ? (
                            <div>
                              <h1 className="swapTitle">SWAP</h1>
                              {typeof bestPath["pathInfo"]["path"][0] ==
                              "object" ? (
                                <div>
                                  <h5 className="partNumber">
                                    part {partOne ? "1" : "2"}/2
                                  </h5>
                                  {partOne ? (
                                    <div>
                                      <div className="swapZone">
                                        <div className="swapPathDiv">
                                          <img
                                            className="imageSingleTokenPath"
                                            src={mapNameToInfo[tokenIn]["logo"]}
                                          />
                                          <h3 className="tokenInPath">
                                            {tokenIn}
                                          </h3>
                                          <h5 className="amountOutPath">
                                            {amountIn}
                                          </h5>
                                        </div>
                                        <div className="spaceInBetweenSingle">
                                          <div
                                            className="partInMiddlePool"
                                            style={{
                                              backgroundColor:
                                                chain == "0x89"
                                                  ? "#ff00000f"
                                                  : "#ff99000f",
                                            }}
                                          >
                                            <img
                                              src={
                                                chain == "0x89"
                                                  ? uniswapLogo
                                                  : pancakeswapLogo
                                              }
                                              className="logoPlatform"
                                            />
                                            <div className="showSelectedPlatform">
                                              <p>
                                                {chain == "0x89"
                                                  ? "UniswapV3"
                                                  : "PancakeswapV3"}
                                              </p>
                                            </div>
                                          </div>
                                          <p className="toTextInMiddle">
                                            {"------>"}
                                          </p>
                                        </div>
                                        <div className="swapPathDiv">
                                          <img
                                            className="imageSingleTokenPath"
                                            src={
                                              chain == "0x89"
                                                ? usdcLogo
                                                : usdtLogo
                                            }
                                          />
                                          <h3 className="tokenInPath">
                                            {
                                              addressToName[
                                                bestPath["pathInfo"][
                                                  "path"
                                                ][0][1]
                                              ]
                                            }
                                          </h3>
                                          <h5 className="amountOutPath">
                                            {middleAmount}
                                          </h5>
                                        </div>
                                      </div>
                                      {approvedFirst ? (
                                        <button
                                          className="approveButtonApproved"
                                          disabled
                                        >
                                          APPROVED ✓
                                        </button>
                                      ) : (
                                        <button
                                          className="approveButton"
                                          onClick={() => approveToken(tokenIn)}
                                        >
                                          APPROVE {tokenIn}
                                        </button>
                                      )}
                                      <button
                                        className="finalSwapButton"
                                        onClick={() =>
                                          swap(
                                            tokenIn,
                                            addressToName[
                                              bestPath["pathInfo"]["path"][0][1]
                                            ],
                                            bestPath["pathInfo"]["fees"][0]
                                          )
                                        }
                                      >
                                        SWAP
                                      </button>
                                    </div>
                                  ) : (
                                    <div>
                                      <div className="swapZone">
                                        <div className="swapPathDiv">
                                          <img
                                            className="imageSingleTokenPath"
                                            src={
                                              chain == "0x89"
                                                ? usdcLogo
                                                : usdtLogo
                                            }
                                          />
                                          <h3 className="tokenInPath">
                                            {
                                              addressToName[
                                                bestPath["pathInfo"][
                                                  "path"
                                                ][0][1]
                                              ]
                                            }
                                          </h3>
                                          <h5 className="amountOutPath">
                                            {middleAmount}
                                          </h5>
                                        </div>
                                        <div className="spaceInBetweenSingle">
                                          <div
                                            className="partInMiddlePool"
                                            style={{
                                              backgroundColor:
                                                chain == "0x89"
                                                  ? "#ff00000f"
                                                  : "#ff99000f",
                                            }}
                                          >
                                            <img
                                              src={
                                                chain == "0x89"
                                                  ? uniswapLogo
                                                  : pancakeswapLogo
                                              }
                                              className="logoPlatform"
                                            />
                                            <div className="showSelectedPlatform">
                                              <p>
                                                {chain == "0x89"
                                                  ? "UniswapV3"
                                                  : "PancakeswapV3"}
                                              </p>
                                            </div>
                                          </div>
                                          <p className="toTextInMiddle">
                                            {"------>"}
                                          </p>
                                        </div>
                                        <div className="swapPathDiv">
                                          <img
                                            className="imageSingleTokenPath"
                                            src={
                                              mapNameToInfo[tokenOut]["logo"]
                                            }
                                          />
                                          <h3 className="tokenInPath">
                                            {tokenOut}
                                          </h3>
                                          <h5 className="amountOutPath">
                                            {amountOut}
                                          </h5>
                                        </div>
                                      </div>
                                      {approvedSecond ? (
                                        <button
                                          className="approveButtonApproved"
                                          disabled
                                        >
                                          APPROVED ✓
                                        </button>
                                      ) : (
                                        <button
                                          className="approveButton"
                                          onClick={() =>
                                            approveToken(
                                              addressToName[
                                                bestPath["pathInfo"][
                                                  "path"
                                                ][0][1]
                                              ]
                                            )
                                          }
                                        >
                                          APPROVE{" "}
                                          {
                                            addressToName[
                                              bestPath["pathInfo"]["path"][0][1]
                                            ]
                                          }
                                        </button>
                                      )}
                                      <button
                                        className="finalSwapButton"
                                        onClick={() =>
                                          swap(
                                            addressToName[
                                              bestPath["pathInfo"]["path"][0][1]
                                            ],
                                            tokenOut,
                                            bestPath["pathInfo"]["fees"][1]
                                          )
                                        }
                                      >
                                        SWAP
                                      </button>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  <div className="swapZone">
                                    <div className="swapPathDiv">
                                      <img
                                        className="imageSingleTokenPath"
                                        src={tokenLogo(
                                          bestPath["pathInfo"]["path"][0]
                                        )}
                                      />
                                      <h3 className="tokenInPath">{tokenIn}</h3>
                                      <h5 className="amountOutPath">
                                        {amountIn}
                                      </h5>
                                    </div>
                                    <div className="spaceInBetweenSingle">
                                      <div
                                        className="partInMiddlePool"
                                        style={{
                                          backgroundColor:
                                            chain == "0x89"
                                              ? "#ff00000f"
                                              : "#ff99000f",
                                        }}
                                      >
                                        <img
                                          src={
                                            chain == "0x89"
                                              ? uniswapLogo
                                              : pancakeswapLogo
                                          }
                                          className="logoPlatform"
                                        />
                                        <div className="showSelectedPlatform">
                                          <p>
                                            {chain == "0x89"
                                              ? "UniswapV3"
                                              : "PancakeV3"}
                                          </p>
                                        </div>
                                      </div>
                                      <p className="toTextInMiddle">
                                        {"------>"}
                                      </p>
                                    </div>
                                    <div className="swapPathDiv">
                                      <img
                                        className="imageSingleTokenPath"
                                        src={tokenLogo(
                                          bestPath["pathInfo"]["path"][1]
                                        )}
                                      />
                                      <h3 className="tokenInPath">
                                        {tokenOut}
                                      </h3>
                                      <h5 className="amountOutPath">
                                        {amountOut}
                                      </h5>
                                    </div>
                                  </div>
                                  {approvedFirst ? (
                                    <button
                                      className="approveButtonApproved"
                                      disabled
                                    >
                                      APPROVED ✓
                                    </button>
                                  ) : (
                                    <button
                                      className="approveButton"
                                      onClick={() => approveToken(tokenIn)}
                                    >
                                      APPROVE {tokenIn}
                                    </button>
                                  )}
                                  <button
                                    className="finalSwapButton"
                                    onClick={() =>
                                      swap(
                                        tokenIn,
                                        tokenOut,
                                        bestPath["pathInfo"]["fees"][0]
                                      )
                                    }
                                  >
                                    SWAP
                                  </button>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="swapCompleted">
                              <h1 className="swappedText">
                                SWAPPED {tokenIn} TO
                              </h1>
                              <img
                                className="imageSingleTokenPath"
                                src={mapNameToInfo[tokenOut]["logo"]}
                              />
                              <h5 className="amountOutPath">
                                {amountOut} {tokenOut}
                              </h5>
                              <button
                                className="finalSwapButton"
                                onClick={handleFinalClose}
                              >
                                CLOSE
                              </button>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </Box>
              </Modal>
            </div>
          ) : (
            <button className="disabledSwapButton" disabled>
              {isLogged ? "Switch chain" : "Connect first"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SwapPart;
