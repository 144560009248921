const ethers = require("ethers");
const polygonNameToAddress = {
  USDT: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
  USDC: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
  WETH: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
  WBTC: "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
  WMATIC: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
  USDCe: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
};
const bnbNameToAddress = {
  WBTC: "0x0555E30da8f98308EdB960aa94C0Db47230d2B9c",
  ETH: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
  USDT: "0x55d398326f99059fF775485246999027B3197955",
  WBNB: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  BTCB: "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
};

const addressToName = {
  "0xc2132D05D31c914a87C6611C10748AEb04B58e8F": "USDT",
  "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359": "USDC",
  "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174": "USDCe",
  "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619": "WETH",
  "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6": "WBTC",
  "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270": "WMATIC",
  "0x0555E30da8f98308EdB960aa94C0Db47230d2B9c": "WBTC",
  "0x2170Ed0880ac9A755fd29B2688956BD959F933F8": "ETH",
  "0x55d398326f99059fF775485246999027B3197955": "USDT",
  "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": "WBNB",
  "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c": "BTCB",
};

const bnbRPC1 = "https://bsc.drpc.org"
const bnbRPC2 = "https://binance.llamarpc.com"
const bnbRPC3 = "https://rpc.ankr.com/bsc"

const polyRPC1 = "https://polygon.llamarpc.com"
const polyRPC2 = "https://polygon.drpc.org"
const polyRPC3 = "https://rpc.ankr.com/polygon"

const ABITokens = require("./data/abiTokens.json");

async function getBalance(token, chain, caller) {
  switch (chain) {
    case "0x89": {
      if (caller == "") return 0;
      const address = polygonNameToAddress[token];
      const provider = new ethers.JsonRpcProvider(
        polyRPC1
      );
      const tokenContract = new ethers.Contract(address, ABITokens, provider);
      const result = parseInt(
        await tokenContract.decimals().then((response) => response)
      ); // return decimals
      const balance = parseFloat(await tokenContract.balanceOf(caller));
      return balance / 10 ** result;
    }
    case "0x38": {
      if (caller == "") return 0;
      const address = bnbNameToAddress[token];
      const provider = new ethers.JsonRpcProvider(
        bnbRPC2
      );
      const tokenContract = new ethers.Contract(address, ABITokens, provider);
      const result = parseInt(
        await tokenContract.decimals().then((response) => response)
      ); // return decimals
      const balance = parseFloat(await tokenContract.balanceOf(caller));
      return balance / 10 ** result;
    }
  }
}

async function getDataOutput(tokenIn, tokenOut, chain) {
  const poolInfo = require("./data/poolsInfo.json");
  let inverse = false;
  let pathsLength;
  let potentialPaths = [];

  try {
    pathsLength = poolInfo[chain][tokenIn][tokenOut]["path"].length;
    potentialPaths = poolInfo[chain][tokenIn][tokenOut]["path"];
  } catch {
    inverse = true;
    pathsLength = poolInfo[chain][tokenOut][tokenIn]["path"].length;
    potentialPaths = poolInfo[chain][tokenOut][tokenIn]["path"];
  }

  let path = [];
  for (let i = 0; i < pathsLength; i++) {
    let singlePath = {
      path: [],
      fees: [],
    };
    if (inverse) {
      for (let j = potentialPaths[i].length - 1; j >= 0; j--) {
        singlePath["path"].push(potentialPaths[i][j]);
      }
      let fees = poolInfo[chain][tokenOut][tokenIn]["fees"];
      if (potentialPaths[i].length == 2 && potentialPaths.length == 1) {
        for (let o = 0; o < fees.length; o++) {
          let extraPath = {
            path: singlePath["path"],
            fees: [fees[o][0]],
          };
          path.push(extraPath);
        }
      } else {
        for (let k = fees[i].length - 1; k >= 0; k--) {
          singlePath["fees"].push(fees[i][k]);
        }
        path.push(singlePath);
      }
    } else {
      for (let j = 0; j < potentialPaths[i].length; j++) {
        singlePath["path"].push(potentialPaths[i][j]);
      }
      let fees = poolInfo[chain][tokenIn][tokenOut]["fees"];
      if (potentialPaths[i].length == 2 && potentialPaths.length == 1) {
        for (let o = 0; o < fees.length; o++) {
          let extraPath = {
            path: singlePath["path"],
            fees: [fees[o][0]],
          };
          path.push(extraPath);
        }
      } else {
        for (let k = 0; k < fees[i].length; k++) {
          singlePath["fees"].push(fees[i][k]);
        }
        path.push(singlePath);
      }
    }
  }
  return path;
}

async function getCleanDataOutput(tokenIn, tokenOut, chain) {
  const path = await getDataOutput(tokenIn, tokenOut, chain);
  let newPath = [];
  const length = path.length;
  for (let i = 0; i < length; i++) {
    let subLength = path[i]["path"].length;
    if (subLength != 2) {
      let subpath = {
        path: [],
        fees: [],
      };
      let tokenSwaps = [];
      let counter = 0;
      let lastPath;
      for (let j = 0; j < subLength; j++) {
        if (counter < 2) {
          counter++;
          tokenSwaps.push(path[i]["path"][j]);
          lastPath = path[i]["path"][j];
          if (counter == 2) {
            subpath["path"].push(tokenSwaps);
            tokenSwaps = [];
          }
        } else {
          tokenSwaps.push(lastPath);
          tokenSwaps.push(path[i]["path"][j]);
          subpath["path"].push(tokenSwaps);
        }
      }
      subpath["fees"] = path[i]["fees"];
      newPath.push(subpath);
    } else {
      newPath.push(path[i]);
    }
  }
  return newPath;
}

function toFixed(x) {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  x = x.toString().split(".");
  return x[0];
}

async function getAmountOutBNB(struct) {
  const bnbQuoterABI = require("./data/bnbABI.json");
  let provider = new ethers.JsonRpcProvider(bnbRPC2);
  let quoter = "0xB048Bbc1Ee6b733FFfCFb9e9CeF7375518e25997";
  const quoterContract = new ethers.Contract(quoter, bnbQuoterABI, provider);
  const quotedAmountOut = await quoterContract.quoteExactInputSingle.staticCall(
    struct
  );
  return quotedAmountOut[0];
}

async function getAmountOutPoly(tokenIn, tokenOut, amount, fee) {
  const abiQuoter = require("./data/abiQuoter.json");
  let provider = new ethers.JsonRpcProvider(
    polyRPC1
  );
  let quoter = "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6";
  const quoterContract = new ethers.Contract(quoter, abiQuoter, provider);
  const quotedAmountOut = await quoterContract.quoteExactInputSingle.staticCall(
    tokenIn,
    tokenOut,
    fee,
    amount,
    0
  );
  return quotedAmountOut;
}

async function getBestOut(tokenIn, tokenOut, amountGiven, chain) {
  const potentialPaths = await getCleanDataOutput(tokenIn, tokenOut, chain);
  let amount = toFixed(
    (amountGiven * (await getDecimals(tokenIn, chain))).toString()
  );
  let amountOut = [];
  switch (chain) {
    case "0x89": {
      let length = potentialPaths.length;
      for (let i = 0; i < length; i++) {
        // loop big path
        if (potentialPaths[i]["fees"].length == 1) {
          let tokenAddressIn = potentialPaths[i]["path"][0];
          let tokenAddressOut = potentialPaths[i]["path"][1];
          let feeUsed = potentialPaths[i]["fees"][0];

          amountOut.push(
            await getAmountOutPoly(
              tokenAddressIn,
              tokenAddressOut,
              amount,
              feeUsed
            )
          );
        } else {
          let previousAmount = amount;
          for (let j = 0; j < potentialPaths[i]["path"].length; j++) {
            let tokenAddressIn = potentialPaths[i]["path"][j][0];
            let tokenAddressOut = potentialPaths[i]["path"][j][1];
            let feeUsed = potentialPaths[i]["fees"][j];

            previousAmount = await getAmountOutPoly(
              tokenAddressIn,
              tokenAddressOut,
              previousAmount,
              feeUsed
            );
          }
          amountOut.push(previousAmount);
        }
      }
      break;
    }
    case "0x38": {
      let length = potentialPaths.length;
      for (let i = 0; i < length; i++) {
        // loop big path
        if (potentialPaths[i]["fees"].length == 1) {
          let tokenAddressIn = potentialPaths[i]["path"][0];
          let tokenAddressOut = potentialPaths[i]["path"][1];
          let feeUsed = potentialPaths[i]["fees"][0];

          const struct = {
            tokenIn: tokenAddressIn,
            tokenOut: tokenAddressOut,
            amountIn: amount,
            fee: feeUsed,
            sqrtPriceLimitX96: 0,
          };
          amountOut.push(await getAmountOutBNB(struct));
        } else {
          let previousAmount = amount;
          for (let j = 0; j < potentialPaths[i]["path"].length; j++) {
            let tokenAddressIn = potentialPaths[i]["path"][j][0];
            let tokenAddressOut = potentialPaths[i]["path"][j][1];
            let feeUsed = potentialPaths[i]["fees"][j];

            const struct = {
              tokenIn: tokenAddressIn,
              tokenOut: tokenAddressOut,
              amountIn: previousAmount,
              fee: feeUsed,
              sqrtPriceLimitX96: 0,
            };
            previousAmount = await getAmountOutBNB(struct);
          }
          amountOut.push(previousAmount);
        }
      }
      break;
    }
  }
  let max = 0;
  let result;
  for (let i = 0; i < amountOut.length; i++) {
    if (parseInt(amountOut) > max) {
      result = potentialPaths[i];
      max = parseInt(amountOut);
    }
  }
  let decimals = await getDecimals(tokenOut, chain);
  return {
    maxOutput: max,
    shortOutputMax: max / decimals,
    pathInfo: result,
  };
}

async function getDecimals(token, chain) {
  switch (chain) {
    case "0x89": {
      const address = polygonNameToAddress[token];
      const provider = new ethers.JsonRpcProvider(
        polyRPC1
      );
      const tokenContract = new ethers.Contract(address, ABITokens, provider);
      const result = parseInt(
        await tokenContract.decimals().then((response) => response)
      );
      return 10 ** result;
    }
    case "0x38": {
      const address = bnbNameToAddress[token];
      const provider = new ethers.JsonRpcProvider(
        bnbRPC2
      );
      const tokenContract = new ethers.Contract(address, ABITokens, provider);
      const result = parseInt(
        await tokenContract.decimals().then((response) => response)
      );
      return 10 ** result;
    }
  }
}

// swapV3Tokens(uint amountInMax,uint256 amountOutMin,address tokenIn,address tokenOut,uint24 fee)
async function swapV3(tokenIn, tokenOut, fee, amountInMax, chain) {
  let amountOut;
  let provider;
  let contract;
  let tokenInAdd;
  let tokenOutAdd;
  const utopiaSwapABI = require("./data/UtopiaswapABI.json");
  switch (chain) {
    case "0x89": {
      tokenInAdd = polygonNameToAddress[tokenIn];
      tokenOutAdd = polygonNameToAddress[tokenOut];
      amountOut =
        (await getAmountOut(tokenInAdd, tokenOutAdd, fee, amountInMax, chain)) *
        0.9985 *
        10 ** 6;
      provider = new ethers.BrowserProvider(window.ethereum);
      let signer = await provider.getSigner();
      contract = new ethers.Contract(
        "0x072575D44F95C04d769C63d5a8D78B7e324c5021",
        utopiaSwapABI,
        signer
      );
      amountInMax = amountInMax * (await getDecimals(tokenIn, chain));
      break;
    }
    case "0x38": {
      tokenInAdd = bnbNameToAddress[tokenIn];
      tokenOutAdd = bnbNameToAddress[tokenOut];
      amountOut =
        (await getAmountOut(tokenInAdd, tokenOutAdd, fee, amountInMax, chain)) *
        10 ** 18;
      provider = new ethers.BrowserProvider(window.ethereum);
      let signer = await provider.getSigner();
      contract = new ethers.Contract(
        "0x072575D44F95C04d769C63d5a8D78B7e324c5021",
        utopiaSwapABI,
        signer
      );
      amountInMax = amountInMax * (await getDecimals(tokenIn, chain));
      break;
    }
  }

  try {
    const tx = await contract.swapV3Tokens(
      toFixed(amountInMax),
      toFixed(amountOut),
      tokenInAdd,
      tokenOutAdd,
      fee
    );
    console.log("Swapping...");
    await tx.wait();
    return 0;
  } catch (e) {
    console.log(e);
    return 1;
  }
}

function swapV2() {}

async function getApprove(user, token, chain) {
  const abiToken = [
    "function allowance(address,address)public view returns(uint256)",
  ];
  let contractAdd;
  let contract;
  let aggregator;
  switch (chain) {
    case "0x89": {
      const provider = new ethers.JsonRpcProvider(
        "https://rpc-mainnet.matic.quiknode.pro"
      );
      contractAdd = polygonNameToAddress[token];
      contract = new ethers.Contract(contractAdd, abiToken, provider);
      aggregator = "0x072575D44F95C04d769C63d5a8D78B7e324c5021";
      break;
    }
    case "0x38": {
      const provider = new ethers.JsonRpcProvider(
        "https://binance.llamarpc.com"
      );
      contractAdd = bnbNameToAddress[token];
      contract = new ethers.Contract(contractAdd, abiToken, provider);
      aggregator = "0x072575D44F95C04d769C63d5a8D78B7e324c5021";
      break;
    }
  }
  let allowance = parseInt(
    await contract.allowance(user, aggregator).then((response) => response)
  );
  return allowance / (await getDecimals(token, chain));
}

async function approve(user, token, chain) {
  const abiToken = ["function approve(address,uint256)public"];
  let contractAdd;
  let aggregator;
  let contract;
  let provider;
  switch (chain) {
    case "0x89": {
      provider = new ethers.BrowserProvider(window.ethereum);
      let signer = await provider.getSigner();
      contractAdd = polygonNameToAddress[token];
      contract = new ethers.Contract(contractAdd, abiToken, signer);
      aggregator = "0x072575D44F95C04d769C63d5a8D78B7e324c5021";
      break;
    }
    case "0x38": {
      provider = new ethers.BrowserProvider(window.ethereum);
      let signer = await provider.getSigner();
      contractAdd = bnbNameToAddress[token];
      contract = new ethers.Contract(contractAdd, abiToken, signer);
      aggregator = "0x072575D44F95C04d769C63d5a8D78B7e324c5021";
      break;
    }
  }
  try {
    const tx = await contract.approve(
      aggregator,
      "115792089237316195423570985008687907853269984665640564039457584007913129639935"
    );
    await tx.wait();
    return 0;
  } catch {
    return 1;
  }
}

async function getAmountOut(
  tokenAddressIn,
  tokenAddressOut,
  feeUsed,
  amountIn,
  chain
) {
  let amountOut;
  let name = addressToName[tokenAddressIn];
  amountIn = toFixed((amountIn * (await getDecimals(name, chain))).toString());
  let decimals;
  switch (chain) {
    case "0x89": {
      amountOut = await getAmountOutPoly(
        tokenAddressIn,
        tokenAddressOut,
        amountIn,
        feeUsed
      );
      decimals = 10 ** 6;
      break;
    }
    case "0x38": {
      const struct = {
        tokenIn: tokenAddressIn,
        tokenOut: tokenAddressOut,
        amountIn: amountIn,
        fee: feeUsed,
        sqrtPriceLimitX96: 0,
      };
      amountOut = await getAmountOutBNB(struct);
      decimals = 10 ** 18;
      break;
    }
  }
  return parseInt(amountOut) / decimals;
}

export {
  getBestOut,
  getBalance,
  getDecimals,
  swapV3,
  swapV2,
  getApprove,
  approve,
  getAmountOut,
};


