const { ethers } = require("ethers");

export async function getBalance(address, providerRpc, wallet) {
  const ABITokens = require("./others/usABI.json");
  const provider = new ethers.JsonRpcProvider(providerRpc);
  const tokenContract = new ethers.Contract(address, ABITokens, provider);
  const result = parseInt(
    await tokenContract.pointsBalance(wallet).then((response) => response)
  );
  return result / 10 ** 18;
}

export async function getPointsAirdrop(user) {
  let tokensBalance = 0;
  const ABIAirdrop = [
    "function pointsBalance(address)external view returns(uint256)",
  ];
  // POLYGON
  let provider = new ethers.JsonRpcProvider("https://polygon.llamarpc.com");
  let tokenContract = new ethers.Contract(
    "0x072575D44F95C04d769C63d5a8D78B7e324c5021",
    ABIAirdrop,
    provider
  );
  tokensBalance += parseInt(
    await tokenContract.pointsBalance(user).then((response) => response)
  );

  // BSC
  provider = new ethers.JsonRpcProvider("https://bsc-rpc.publicnode.com");
  tokenContract = new ethers.Contract(
    "0x072575D44F95C04d769C63d5a8D78B7e324c5021",
    ABIAirdrop,
    provider
  );
  tokensBalance += parseInt(
    await tokenContract.pointsBalance(user).then((response) => response)
  );

  return tokensBalance / 1e18;
}
