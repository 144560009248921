export const desktopOS = [
  {
    label: "Public (Airdrop)",
    value: 80,
  },
  {
    label: "Marketing / Listings",
    value: 12.5,
  },
  {
    label: "Development (salaries)",
    value: 5,
  },
  {
    label: "Team",
    value: 2.5,
  },
];

export const valueFormatter = (item: { value: number }) => `${item.value}%`;
