import "./App.css";
import Header from "./components/Header";
import "bootstrap/dist/css/bootstrap.css";
import { ParticleComp } from "./components/Particle";
import SwapPart from "./components/swap/SwapPart";
import useWindowDimensions from "./components/Others/Size/useWindowDimentions";
import MobileHeader from "./components/MobileHeader";
import MobileSwapPart from "./components/swap/SwapPartMobile";

const App = () => {
  const { width } = useWindowDimensions();
  return (
    <div className="App">
      {width > 800 ? (
        <div>
          <ParticleComp />
          <Header />
          <SwapPart />
        </div>
      ) : (
        <div>
          <ParticleComp />
          <MobileHeader />
          <MobileSwapPart />
        </div>
      )}
    </div>
  );
};

export default App;
