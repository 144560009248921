import mainLogo from "../images/utopi.png";

import "./style/style.css";
import Metamask from "./Web3/Metamask";
import SelectChain from "./header/SelectChain";
import MobileAirdrop from "./header/MobileAirdrop";
import useWindowDimensions from "./Others/Size/useWindowDimentions";

const MobileHeader = () => {
  const { width } = useWindowDimensions();
  return (
    <div>
      <div className="desktopFadedHeader">
        <div>
          <header className="desktop-header">
            <div>
              <a href="/">
                <img className="mainLogo" src={mainLogo} />
              </a>
            </div>
            {width > 460 ? (
              <div className="zoneUpTop">
                <MobileAirdrop />
                <SelectChain />
                <Metamask />
              </div>
            ) : (
              <div className="zoneUpTop">
                <Metamask />
              </div>
            )}
          </header>
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
