import type { Container, Engine } from "tsparticles-engine";
import { loadFirePreset } from "tsparticles-preset-fire";
import { useCallback, useRef } from 'react';
import dataOptions from  "./Others/data/particles.json";
import Particles from "react-particles";

export const ParticleComp = () => {
    const Cond1 = useRef(false);
    const Cond2 = useRef(false);

    const particlesInit = useCallback(async (engine: Engine) => {
        if (!Cond1.current) {
          await loadFirePreset(engine);
          Cond1.current = true;
        }
    });

    const particlesLoaded = useCallback(
        async (container: Container | undefined) => {
          if (!Cond2.current) {
            await container;
            Cond2.current = true;
          }
        }
    );
    return(
        <Particles 
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={dataOptions}
      />
    )


}