const changeWallet = (
  state = {
    wallet: "",
    isLogged: false,
    chain: "0x89",
    login: 0,
    airdrop: 0,
  },
  action
) => {
  const { CurrentWallet, isLogged, chain, login, airdrop } = action;

  switch (action.type) {
    case "changeWallet":
      if (CurrentWallet === "" || CurrentWallet === undefined) {
        return {
          ...state,
          wallet: "",
          isLogged: false,
          chain:
            window.ethereum && window.ethereum._state.isUnlocked
              ? window.ethereum.chainId
              : "0x89",
          login: 0,
          airdrop: 0,
        };
      } else {
        return {
          ...state,
          wallet: CurrentWallet,
          isLogged: isLogged || state.isLogged,
          chain: chain || state.chain,
          login: (login || state.login) + 1,
          airdrop: airdrop || state.airdrop,
        };
      }
    default:
      return state;
  }
};

export default changeWallet;
