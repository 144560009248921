import React, { useEffect, useState, useRef, useCallback } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import "../style/style.css";
import { FaUserAlt } from "react-icons/fa";
import useWindowDimensions from "../Others/Size/useWindowDimentions";

const Metamask = () => {
  const ethers = require("ethers");
  const [currentAccount, setCurrentAccount] = useState("");
  const [connected, setConnected] = useState();
  const { width } = useWindowDimensions();

  const isLogged = useSelector((state) => state.User.isLogged);
  const numberLogin = useSelector((state) => state.User.login);
  const wallet = useSelector((state) => state.User.wallet);
  const chain = useSelector((state) => state.User.chain);
  const dispatch = useDispatch();
  const changeWallet = useCallback(async () =>
    dispatch({
      type: "changeWallet",
      CurrentWallet: currentAccount,
      isLogged: true,
      chain: chain,
      login: numberLogin,
    })
  );

  useEffect(() => {
    changeWallet();
  }, [currentAccount]);

  function changeLogged() {
    changeWallet();
  }

  if (window.ethereum)
    window.ethereum.on("accountsChanged", function (accounts) {
      setConnected(accounts[0]);
    });

  async function connectToMetamask() {
    if (!isLogged && currentAccount != "") changeLogged();
    try {
      const Web3Provider = new ethers.BrowserProvider(window.ethereum);
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const signer = await Web3Provider.getSigner(connected);
      setCurrentAccount(signer.address);
      await changeWallet();
      toast.success("logged in!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (e) {
      toast.error("error to login!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  if (wallet == "") {
    return (
      <div>
        <ToastContainer />
        <button
          className="loginButton"
          role="button"
          onClick={() => connectToMetamask()}
        >
          {width > 670 ? "Connect Wallet" : "Connect"}
        </button>
      </div>
    );
  } else {
    return (
      <div>
        <ToastContainer />
        <a className="profileButton">
          <div className="accountHeader">
            <div>
              <FaUserAlt />
            </div>
            <p className="accountText">
              0x{currentAccount.slice(2, 5)}...{currentAccount.slice(39, 42)}
            </p>
          </div>
        </a>
      </div>
    );
  }
};

export default Metamask;
