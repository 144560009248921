import mainLogo from "../images/utopi.png";
import "./style/style.css";
import Metamask from "./Web3/Metamask";
import SelectChain from "./header/SelectChain";
import Airdrop from "./header/Airdrop";

const Header = () => {

  return (
    <div>
      <div className="desktopFadedHeader">
        <div>
          <header className="desktop-header">
            <div>
              <a href="/">
                <img className="mainLogo" src={mainLogo} />
              </a>
            </div>
            <div className="zoneUpTop">
              <Airdrop />
              <SelectChain />
              <Metamask />
            </div>
          </header>
        </div>
      </div>
    </div>
  );
};

export default Header;
